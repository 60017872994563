<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="page-title-right">
            <router-link
              :to="{
                name: 'program-owner-handover',
              }"
            >
              <button
                type="button"
                class="btn btn-sm btn-blue waves-effect waves-light mb-2"
              >
                <i class="mdi mdi-arrow-left"></i>
                Back
              </button>
            </router-link>
          </div>
          <h4 class="page-title">Program Owner Handover Detail</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-end">
              <router-link :to="{ name: 'program-owner-handover-create' }">
                <button
                  v-show="!loading"
                  type="button"
                  class="btn btn-sm btn-blue waves-effect waves-light mb-2"
                  v-if="hasPermissions(['module-group-create'])"
                >
                  <i class="mdi mdi-plus-circle"></i> Add Handover
                </button>
              </router-link>
            </div>
            <place-holder v-if="loading"></place-holder>
            <div class="table-responsive">
              <table
                class="table table-striped dt-responsive w-100 mb-3"
                id="handover-list-datatable"
                v-show="!loading"
              >
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Issued Date</th>
                    <th>Program Name</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Handover Status</th>
                    <th>Handover Remark</th>
                    <th>Declined Reason</th>
                    <th v-if="hasPermissions(['trainer-edit'])">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(
                      programOwnerHandover, index
                    ) in handoverLists.handover_programs"
                    :key="index"
                  >
                    <td>{{ ++index }}</td>
                    <td>{{ programOwnerHandover.issued_date }}</td>
                    <td>{{ programOwnerHandover.program_name }}</td>
                    <td>{{ programOwnerHandover.trainer_from.name }}</td>
                    <td>{{ programOwnerHandover.trainer_to.name }}</td>
                    <td>
                      <span
                        v-if="programOwnerHandover.status == 'Requested'"
                        class="text-warning"
                        >Requested by
                        {{ programOwnerHandover.requested_by.name }}
                      </span>
                      <span
                        v-if="programOwnerHandover.status == 'Approved'"
                        class="text-success"
                        >Received by
                        {{ programOwnerHandover.changed_status_by.name }}
                      </span>
                      <span
                        v-if="programOwnerHandover.status == 'Rejected'"
                        class="text-danger"
                        >Declined by
                        {{ programOwnerHandover.changed_status_by.name }}</span
                      >
                    </td>
                    <td>{{ programOwnerHandover.remark }}</td>
                    <td>{{ programOwnerHandover.declined_reason }}</td>
                    <td v-if="hasPermissions(['trainer-edit'])">
                      <p
                        v-if="
                          $store.state.auth.user.employee_id ==
                            programOwnerHandover.requested_by.employee_id &&
                          programOwnerHandover.status == 'Requested'
                        "
                        class="action-icon"
                        @click="showModal(programOwnerHandover)"
                      >
                        <i class="mdi mdi-square-edit-outline"></i>
                      </p>
                      <p
                        v-else-if="
                          hasPermissions(['program-owner-handover-edit']) &&
                          programOwnerHandover.status == 'Requested'
                        "
                        class="action-icon"
                        @click="showModal(programOwnerHandover)"
                      >
                        <i class="mdi mdi-square-edit-outline"></i>
                      </p>
                      <p
                        v-else
                        class="action-icon me-5"
                        style="cursor: not-allowed"
                        data-bs-toggle="tooltip"
                        title="You cannot change Handover Proess!"
                      >
                        <i class="mdi mdi-square-edit-outline"></i>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <EnrollModal :modalId="'confirm-modal'">
    <template v-slot:header> Program Handover Edit Form </template>
    <template v-slot:body>
      <div class="card-body" style="padding: 0px !important">
        <div class="table-responsive" v-show="!loading">
          <table
            class="table table-centered table-nowrap table-hover mb-0"
            v-show="!loading"
          >
            <tbody>
              <tr class="custom-tr">
                <th>
                  <span class="ms-1">Program Name:</span>
                </th>
                <td>
                  <span class="ms-1">{{ handover_detail.program_name }}</span>
                </td>
              </tr>
              <tr class="custom-tr">
                <th>
                  <span class="ms-1">Issued Date: </span>
                </th>
                <td>
                  <span class="ms-1">{{ handover_detail.issued_date }}</span>
                </td>
              </tr>
              <tr class="custom-tr">
                <th>
                  <span class="ms-1">Trainer From:</span>
                </th>
                <td>
                  <span class="ms-1">{{
                    handover_detail.trainer_from.name
                  }}</span>
                </td>
              </tr>
              <tr class="custom-tr">
                <th>
                  <span class="ms-1">Trainer To:</span>
                </th>
                <td>
                  <v-select
                    id="vue-selectCss"
                    class="ms-1 custom-selectCSS"
                    v-model="handover_detail.trainer_to"
                    label="name"
                    :options="trainers"
                  >
                  </v-select>
                </td>
              </tr>
              <tr class="custom-tr">
                <th>
                  <span class="ms-1">Handover Remark:</span>
                </th>
                <td>
                  <span class="ms-1">{{ handover_detail.remark }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <button
        type="button"
        class="btn w-sm btn-secondary me-4"
        data-bs-dismiss="modal"
      >
        Close
      </button>
      <button
        :disabled="isLoading ? true : false"
        type="button"
        @click="updateHandover"
        class="btn w-sm btn-success waves-effect waves-light"
      >
        <span
          v-if="isLoading"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        {{ isLoading == true ? "Loading..." : "Confirm" }}
      </button>
    </template>
  </EnrollModal>
</template>

<script>
//Datatable programOwnerHandover
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import { useToast } from "vue-toastification";
import EnrollModal from "../../components/shared/Modal.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

//Mixins
import userHasPermissions from "../../mixins/userHasPermissions";

export default {
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    return { toast };
  },
  components: {
    EnrollModal,
    vSelect,
  },
  data() {
    return {
      handoverLists: [],
      handover_detail: {
        id: "",
        program_name: "",
        issued_date: "",
        trainer_from: "",
        trainer_to: "",
        remark: "",
        declined_reason: "",
      },
      trainers: [],
      loading: false,
      isLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {
    async getAllProgramOwnerHandover() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v1/program-handover/${this.$route.params.id}`
        )
        .then((response) => {
          this.handoverLists = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
      $("#handover-list-datatable").DataTable();
    },
    getTrainers() {
      this.loading = true;
      this.$Progress.start();
      axios
        .get(`${this.baseUrl}admin/v1/trainers`)
        .then((response) => {
          this.trainers = response.data.data.map(function (val) {
            //for active trainers
            if (val.status == 1) {
              return {
                id: val.id,
                employee_id: val.employee_id,
                name: val.name,
              };
            }
          });
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Trainer!");
        });
    },
    showModal(handover_detail) {
      this.handover_detail.id = handover_detail.id;
      this.handover_detail.program_name = handover_detail.program_name;
      this.handover_detail.issued_date = handover_detail.issued_date;
      this.handover_detail.trainer_from = handover_detail.trainer_from;
      this.handover_detail.trainer_to = handover_detail.trainer_to;
      this.handover_detail.remark = handover_detail.remark;
      this.handover_detail.declined_reason = handover_detail.declined_reason;

      $("#confirm-modal").modal("show");
    },
    updateHandover() {
      if (
        this.handover_detail.trainer_to == "" ||
        this.handover_detail.trainer_to == null
      ) {
        this.toast.error("Please add Trainer!");
        return;
      }
      this.isLoading = true;
      axios
        .put(
          `${this.baseUrl}admin/v1/program-handover/${this.handover_detail.id}`,
          { trainer_to: this.handover_detail.trainer_to.employee_id }
        )
        .then(() => {
          this.getAllProgramOwnerHandover();
          $("#confirm-modal").modal("hide");
          this.toast.success("Successfully Changed!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#handover-list-datatable").DataTable().draw();
    },
  },
  created() {
    this.clearFilter();
    this.getAllProgramOwnerHandover();
    this.getTrainers();
  },
};
</script>

<style>
#vue-selectCss ul {
  position: relative !important;
}

.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
</style>

<style scoped>
.custom-tr {
  border-style: hidden !important;
}
</style>
